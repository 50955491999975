<template>
  <el-dialog
    class="common-applicants__choose"
    :title="title[type] + '报项'"
    :before-close="handleClose"
    :visible.sync="visible"
    width="580px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      class="form-wrap"
      ref="formRef"
      :hide-required-asterisk="true"
      :model="formData"
      :rules="formRules"
      label-width="140px"
    >
      <el-form-item prop="name">
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          运动员：
        </template>
        <el-input
          placeholder="点击选择运动员"
          v-model="user"
          readonly
          style="width: 100%; max-width: 400px;"
          @click.native="handleChoose"
          :disabled="type === 'edit'"
        >
          <template slot="append">
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>

      <!-- <el-form-item label="身高（cm）" prop="height">
        <el-input v-model="formData.height" placeholder=""></el-input>
      </el-form-item>

      <el-form-item label="体重（kg）" prop="weight">
        <el-input v-model="formData.weight" placeholder=""></el-input>
      </el-form-item>

      <el-form-item label="学习周期" prop="learningCycle">
        <el-input v-model="formData.learningCycle" placeholder=""></el-input>
      </el-form-item> -->

      <el-form-item label="推手项目类型" prop="tuishouType">
        <el-select
          v-model="formData.tuishouType"
          placeholder=""
          style="width: 100%;"
        >
          <el-option
            v-for="(item, index) in tuishouTypeList"
            :key="index"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="体重级别" prop="weight">
        <el-select
          v-model="formData.weight"
          placeholder=""
          style="width: 100%;"
        >
          <el-option
            v-for="(item, index) in weightList"
            :key="index"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="联系电话" prop="telephone">
        <el-input
          v-model="formData.telephone"
          placeholder="手机号码"
          maxlength="11"
        ></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="项目名称" prop="projectName">
        <el-select
          style="width: 100%;"
          v-model="formData.projectName"
          placeholder="请选择"
          :disabled="type !== 'add'"
        >
          <el-option label="太极推手" value="太极推手"></el-option>
          <el-option label="青少年散打项目" value="青少年散打项目"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>

    <div class="form-footer">
      <el-button
        type="primary"
        @click="handleAdd('formRef')"
        v-if="type === 'add'"
        >新增</el-button
      >
      <el-button v-else type="primary" @click="handleUpdate('formRef')">
        修改
      </el-button>
      <el-button @click="handleCancel('formRef')">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    typeOptions: {},
    instrumentTradition: {},
    instrumentManChangArr: {},
    instrumentWomenChangArr: {},
    instrumentNanArr: {},
    instrumentTaiji: {}
  },
  data() {
    function validatePhone(rule, value, callback) {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    return {
      recordDetail: {}, // 报项详情
      visible: false,
      type: "add",
      title: {
        add: "新增",
        edit: "编辑"
      },
      formData: {
        id: "",
        name: "",
        group: "",
        // height: "",
        weight: "",
        tuishouType: "",
        // learningCycle: "",
        // telephone: "",
        projectName: "太极推手"
      },
      formRules: {
        name: [{ required: true, message: "请选择报项人", trigger: "change" }],
        type: [{ validator: this.validateType, trigger: "change" }],
        height: [{ required: true, message: "必填", trigger: "change" }],
        weight: [{ required: true, message: "必填", trigger: "change" }],
        learningCycle: [{ required: true, message: "必填", trigger: "change" }],
        telephone: [{ validator: validatePhone, trigger: "change" }],
        projectName: [{ required: true, message: "必填", trigger: "change" }],
        tuishouType: [{ required: true, message: "必填", trigger: "change" }]
      },
      commonApplicant: {},
      index: -1,
      showAddProject: false, // 显示增加项目
      showCancelProject: false, // 显示删除项目
      showProject2: false, // 第二个项目默认隐藏
      boxingOptions: [],
      instrumentOptions: [],
      tuishouTypeList: [
        {
          label: "定步推手",
          value: "定步推手"
        },
        {
          label: "活步推手",
          value: "活步推手"
        }
      ],
      weightList: [
        {
          label: "50Kg（>50Kg－≤55Kg）",
          value: "50kg"
        },
        {
          label: "55Kg（>55Kg－≤60Kg）",
          value: "55kg"
        },
        {
          label: "60Kg（>60Kg－≤65Kg）",
          value: "60kg"
        },
        {
          label: "65Kg（>65Kg－≤70Kg）",
          value: "65kg"
        },
        {
          label: "70Kg（>70Kg－≤75Kg）",
          value: "70kg"
        },
        {
          label: "75Kg（＞75Kg－≤80Kg）",
          value: "75kg"
        },
        {
          label: "80Kg（＞80Kg－≤85Kg）",
          value: "80kg"
        },
        {
          label: "85kg以上级（＞85kg）",
          value: "＞85kg"
        }
      ]
    };
  },
  computed: {
    list() {
      return this.$store.state.applicants.applicants;
    },
    user() {
      if (this.formData.name) {
        return `${this.formData.name}(${this.formData.cardId.substr(-6, 6)})-${
          this.formData.sex
        }`;
      } else {
        return "";
      }
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    show(type, index, data) {
      this.type = type;
      this.index = index;
      if (data) {
        this.recordDetail = JSON.parse(JSON.stringify(data));
        this.formData = {
          id: this.recordDetail.id,
          name: this.recordDetail.name,
          cardId: this.recordDetail.cardId,
          sex: this.recordDetail.sex,
          height: this.recordDetail.height,
          weight: this.recordDetail.weight,
          learningCycle: this.recordDetail.learningCycle,
          telephone: this.recordDetail.telephone,
          projectName: this.recordDetail.projectName,
          tuishouType: this.recordDetail.tuishouType
        };
      } else {
        this.formData = {
          id: "",
          name: "",
          cardId: "",
          sex: "",
          group: "",
          height: "",
          weight: "",
          learningCycle: "",
          telephone: "",
          projectName: "",
          tuishouType: ""
        };
        this.showAddProject = true;
        this.showCancelProject = false;
      }

      this.visible = true;
      this.$nextTick(() => {
        this.$refs["formRef"].clearValidate();
      });
    },
    hide() {
      this.handleClose();
    },
    // 关闭前的操作
    handleClose() {
      this.visible = false;
      this.$refs["formRef"].clearValidate();
    },
    handleChoose() {
      if (this.type === "add") {
        this.$emit("choose-user");
      }
    },
    applicantsConfirm(row) {
      console.log("row", row);
      // let group = row.kickboxingGroup;

      this.formData = {
        ...this.formData,
        ...row
        // group
      };

      this.$nextTick(() => {
        this.$refs["formRef"].validateField(["name"]);
      });
    },
    validateType(rule, value, callback) {
      if (!this.formData.type) {
        callback(new Error("至少选择一类项目。"));
      } else {
        callback();
      }
    },

    handleAdd(form) {
      if (!this.formData.tuishouGroup) {
        this.$message.warning("当前运动员年龄不符合比赛要求，报名失败");
        return false;
      }
      this.formData.group = this.formData.tuishouGroup;
      // console.log("this.$refs[form]", this.$refs[form]);
      // if (this.formData.projectName === "太极推手") {
      //   // this.formData.group = this.formData.group;
      // } else {
      //   this.formData.group =
      //     this.formData.kickboxingGroup || this.formData.group;
      // }
      this.$refs[form].validate(valid => {
        console.log("valid", valid);
        if (valid) {
          this.$emit(
            "confirm",
            this.index,
            JSON.parse(JSON.stringify(this.formData))
          );
        }
      });
    },
    handleUpdate(form) {
      this.formData.group = this.formData.tuishouGroup;
      // if (this.formData.projectName === "太极推手") {
      //   // this.formData.group = this.formData.group;
      // } else {
      //   this.formData.group =
      //     this.formData.kickboxingGroup || this.formData.group;
      // }
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$emit(
            "confirm",
            this.index,
            JSON.parse(JSON.stringify(this.formData))
          );
        }
      });
    },

    handleCancel() {
      this.visible = false;
    },

    // 删除项目的按钮
    handleCancelProject() {
      if (this.formData.instrument || this.showProject2) {
        this.showProject2 = false;
        this.formData.instrument1 = "";
        this.formData.instrumentRoutine1 = "";
        this.showAddProject = true;
        this.showCancelProject = false;
        return 0;
      }
    },
    showRoutineName(boxing) {
      for (let i = 0; i < this.boxingTradition.length; i++) {
        const element = this.boxingTradition[i];
        let findObj = element?.options.find(
          item => item.label === boxing && item.isCustomRoutineName
        );
        if (findObj) {
          return true;
        }
      }
      return false;
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
.form-footer {
  text-align: center;
}
.form-project {
  margin-bottom: 17px;
  text-align: center;
}
</style>

<style scoped>
.common-applicants__choose >>> .el-dialog {
  width: 580px;
  max-width: 90%;
}
</style>
