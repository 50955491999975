/* * @Desc: 步骤3-集体项目 * @Author: liym * @Date: 2020-02-14 17:57:29 * @Last
Modified by: liym * @Last Modified time: 2020-03-09 01:12:28 */

<template>
  <div class="step3-person" v-loading="loading">
    <div class="empty" v-if="list.length < 1">暂无数据，请新增报项</div>
    <el-collapse v-else v-model="active" class="box">
      <el-collapse-item
        :name="index"
        v-for="(item, index) in list"
        :key="index"
      >
        <template slot="title">
          {{ index + 1 }}. {{ item.contestants.length }}名运动员({{
            item.item
          }})
          <span style="color:#409EFF" v-if="item.itemRoutine">
            ——{{ item.itemRoutine }}
          </span>
          <div class="title-right">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click.stop="edit(index)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click.stop="del(item.id)"
              >删除</el-button
            >
          </div>
        </template>
        <div class="personal-info-item">
          <el-form class="form-wrap" label-width="140px">
            <el-form-item label="项目名称：" prop="duelType">
              <el-input
                style="width: 100%;"
                v-model="collective[index]"
                readonly
              />
            </el-form-item>
            <el-form-item
              label="姓名："
              prop="name"
              v-for="(contestant, contestantIndex) in item.contestants"
              :key="contestantIndex"
            >
              <el-input
                v-model="contestants[index][contestantIndex]"
                readonly
                style="width: 100%; max-width: 400px;"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div style="text-align:center; padding: 30px 0 0;">
      <el-button
        :disabled="canNotAdd"
        :title="handleAddTips"
        type="primary"
        @click="handleAdd()"
        >新增武术集体报项</el-button
      >
    </div>

    <collective-edit
      ref="edit"
      :collective-options="collectiveOptions"
      @confirm="confirm"
      @choose-user="handleChoose"
    ></collective-edit>
  </div>
</template>

<script>
import { nameFormat } from "@/filters";
import {
  delCollective,
  putCollective,
  addCollective,
  getCollectives,
  delCollectiveAdmin,
  putCollectiveAdmin,
  addCollectiveAdmin,
  getCollectivesAdmin
} from "@/api";
import collectiveEdit from "./collective-edit";
export default {
  name: "step3Collective",
  props: {
    // data: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // }
  },
  data() {
    return {
      loading: false,
      active: [],
      // 集体项目选项 0：拳术    1：器械
      // collectiveOptions: [
      //   { label: '集体太极八法五步', type: 0 },
      //   { label: '集体太极拳术', type: 0 },
      //   { label: '集体太极器械', type: 1 }
      // ]
      collectiveOptions: ["集体拳术", "集体器械（含拳术和器械混编项目）"],
      list: [],
      canNotAdd: false, // 不可以添加
      handleAddTips: "", // “新增集体报项”按钮的提示信息
      maxTeamNum: null // 最大团队数
    };
  },
  computed: {
    // list: {
    //   get () {
    //     return this.data
    //   },
    //   set (val) {
    //     this.$emit('update:data', val)
    //   }
    // },
    collective() {
      let arr = [];
      this.list.forEach((item, index) => {
        arr[index] = item.itemRoutine
          ? `${item.itemRoutine}`
          : `${item.itemRoutine}${item.item}`;
      });
      return arr;
    },
    contestants() {
      let arr = [];
      this.list.forEach((items, indexs) => {
        if (arr[indexs] === undefined) {
          arr[indexs] = [];
        }
        items.contestants.forEach((item, index) => {
          arr[indexs][index] = nameFormat(item, false);
        });
      });
      return arr;
    }
  },
  created() {
    this.getList();
  },
  mounted() {},
  watch: {
    list() {
      if (this.maxTeamNum && this.list.length > this.maxTeamNum) {
        this.canNotAdd = true;
        this.handleAddTips = `一支队伍最多填报${this.maxTeamNum}个集体项目`;
      } else {
        this.canNotAdd = false;
        this.handleAddTips = "点击创建集体报项";
      }
    }
  },
  filters: {
    contestant(contestants) {
      let arr = "";
      contestants.forEach((item, index) => {
        arr += index === 0 ? item.name : `、${item.name}`;
      });
      return arr;
    }
  },
  methods: {
    nameFormat(contestants, showGroupName = true) {
      if (contestants && contestants.name && contestants.name.length > 0) {
        return `${contestants.name}(${contestants.cardId.substr(-6, 6)})${
          showGroupName ? contestants.group : ""
        }`;
      } else {
        return "";
      }
    },

    getList() {
      this.loading = true;

      if (this.$store.state.user.authority === 0) {
        // 用户
        getCollectives()
          .then(res => {
            console.log(res);
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
            // this.applicants = res.data
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      } else {
        // 管理员
        getCollectivesAdmin()
          .then(res => {
            // console.log(res)
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
            // this.applicants = res.data
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    handleChoose(index, data, projectType) {
      let choose = this.list.concat([data]);
      // choose = [data]
      this.$emit("choose-user", "collective", index, choose, projectType);
    },
    // 添加项
    handleAdd() {
      this.$refs.edit.show("add", -1);
      // this.active = ['edit']
    },

    applicantsConfirm(row, index) {
      this.$refs["edit"].applicantsConfirm(row, index);
    },
    // 删除
    del(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (this.$store.state.user.authority === 0) {
            // 用户
            delCollective(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            // 管理员
            delCollectiveAdmin(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                console.error(err);
              });
          }
        })
        .catch(() => {});
    },
    edit(index) {
      let person = this.list[index];
      this.$refs.edit.show("edit", index, person);
    },
    // 验证是否有重复的证件号
    checkIdcard(index, row) {
      let flag = false;
      for (let i = 0; i < this.list.length; i++) {
        if (flag) {
          break;
        }
        for (let j = 0; j < this.list[i].contestants.length; j++) {
          let item = this.list[i].contestants[j];
          if (flag) {
            break;
          }
          for (let k = 0; k < row.contestants.length; k++) {
            let contestants = row.contestants[k];
            if (index === -1) {
              if (item.cardId === contestants.cardId) {
                flag = true;
                break;
              }
            } else if (index !== i) {
              if (item.cardId === contestants.cardId) {
                flag = true;
                break;
              }
            }
          }
        }
      }
      return flag;
    },
    checkItemType(index, row) {
      // console.log('index', index)
      // console.log('row', row)
      // console.log('this.list', this.list)
      let flag = false;
      for (let i = 0; i < this.list.length; i++) {
        if (flag) {
          break;
        }
        if (index === -1 || index === i) {
          if (this.list[i].itemType === row.itemType) {
            flag = true;
            break;
          }
        }
        // else if () {
        //   if (this.list[i].itemType === row.itemType) {
        //     flag = true
        //     break
        //   }
        // }
      }
      return flag;
    },
    // 保存
    confirm(index, row) {
      if (this.checkIdcard(index, row)) {
        this.$alert("填报失败，一人只能填报一组集体项目。", "提示", {
          confirmButtonText: "确定",
          type: "error"
        });
        return 0;
      }

      // else if (this.checkItemType(index, row)) {
      //   this.$alert('填报失败，不能报填同种类型的项目。', '提示', {
      //     confirmButtonText: '确定',
      //     type: 'error'
      //   });
      //   return 0
      // } else
      // 新增集体项目
      if (index === -1) {
        // if (this.list.length) {
        //   if (this.list[0].item === row.item) {
        //     this.$alert("填报失败，不能报填同种类型的项目。", "提示", {
        //       confirmButtonText: "确定",
        //       type: "error"
        //     });
        //     return 0;
        //   }
        // }

        if (this.$store.state.user.authority === 0) {
          // 用户
          addCollective(row)
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.edit.hide();
            })
            .catch(err => {
              this.$alert(err, "系统提示", {
                type: "error"
              });
            });
        } else {
          // 管理员
          addCollectiveAdmin(row)
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.edit.hide();
            })
            .catch(err => {
              console.error(err);
            });
        }
      } else if (this.$store.state.user.authority === 0) {
        // 用户
        putCollective(row.id, row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.edit.hide();
          })
          .catch(err => {
            this.$alert(err, "系统提示", {
              type: "error"
            });
          });
      } else {
        // 管理员
        putCollectiveAdmin(row.id, row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.edit.hide();
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  },
  components: {
    collectiveEdit
  }
};
</script>

<style scoped lang="scss">
.empty {
  height: 100px;
  line-height: 120px;
  text-align: center;
}
.box {
  min-height: 200px;
  max-height: 400px;
  line-height: 400px;
  overflow: auto;
}
.title-right {
  position: absolute;
  right: 35px;
}
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-footer {
    text-align: center;
  }
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
</style>
