/* * @Desc: 选择常用运动员 * @Author: liym * @Date: 2020-02-14 16:40:41 * @Last
Modified by: liym * @Last Modified time: 2020-03-09 00:43:15 */

<template>
  <el-dialog
    class="common-applicants__choose"
    title="选择导入运动员信息"
    width="800px"
    :before-close="handleClose"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-input
      style="width:180px;margin-bottom:5px"
      v-model="certificate"
      placeholder="请输入证件号码搜索"
      clearable
    ></el-input>

    <el-checkbox
      v-if="type === 'collective' || type === 'freeBoxingRef'"
      v-model="showUnSelectedPlayer"
      @change="handleShowUnSelectedPlayer"
      >显示不可选的运动员</el-checkbox
    >
    <el-table
      class="common-table"
      empty-text="没有符合条件的运动员"
      :data="tableData"
      v-loading="loading"
      stripe
      border
      height="400px"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
      <el-table-column
        prop="certificate"
        label="证件类型"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="cardId"
        label="证件号码"
        min-width="170"
      ></el-table-column>
      <el-table-column prop="sex" label="性别" width="50"></el-table-column>
      <el-table-column
        prop="birth"
        label="出生日期"
        width="100"
      ></el-table-column>
      <!-- <el-table-column prop="group" label="竞赛组别" width="107" sortable></el-table-column> -->
      <el-table-column align="center" label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            :disabled="choose.indexOf(scope.row.cardId) !== -1"
            @click="handleChoose(scope.row)"
            >选择</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getMembers, getMembersAdmin } from "@/api";
import { deepClone } from "@/utils/DeepClone";

export default {
  props: {},
  data() {
    return {
      loading: true,
      visible: false,
      choose: [],
      type: "",
      index: -1,
      certificate: "", // 输入框内的身份证号码
      tableData: [], // 显示的运动员
      allTableData: [], // 所有的运动员
      showUnSelectedPlayer: false
    };
  },
  computed: {
    // list () {
    //   return this.$store.state.project.members
    // }
    // tableData: {
    //   get () {
    //     return this.$store.state.project.members
    //   },
    //   set () {
    //     console.log(1)
    //   }
    // }
  },
  created() {
    this.getList();
  },
  mounted() {},
  watch: {
    certificate(value) {
      this.tableData = this.$store.state.project.members;
      if (!value) {
        this.tableData = this.$store.state.project.members;
      }
      // console.log('value', value)
      let arr = [];
      this.$store.state.project.members.forEach((item, index) => {
        // console.log('item.cardId', item.certificate)
        if (item.cardId.indexOf(value) !== -1) {
          arr.push(item);
        }
      });
      if (!arr.length) {
        this.tableData = [];
        return;
      }
      this.tableData = arr;
    }
  },
  methods: {
    // type分个人、对练、集体；projectType分添加和修改
    show(type, index, list, projectType) {
      // console.log(type, index, list)
      this.visible = true;
      this.index = index;
      this.type = type;
      // 已选过的运动员不可隐藏，运动员要隐藏
      console.log("type", type);
      this.choose = [];
      if (type === "collective") {
        this.choose = this.getIdcard(type, list);
        console.log("this.choose :>> ", this.choose);
        console.log("this.allTableData :>> ", this.allTableData);
        this.tableData = this.allTableData.filter(item => {
          return !this.choose.find(el => el === item.cardId);
        });
      } else {
        this.tableData = deepClone(this.allTableData);
      }
    },
    getIdcard(type, list) {
      let contestants = [];
      if (type === "person") {
        list.forEach(item => {
          contestants.push(item.cardId);
        });
      } else {
        list.forEach(item => {
          item.contestants.forEach(user => {
            contestants.push(user.cardId);
          });
        });
      }
      return contestants;
    },

    hide() {
      this.handleClose();
    },
    // 关闭前的操作
    handleClose() {
      this.visible = false;
    },
    handleChoose(row) {
      // console.log('row', row)
      this.$emit("confirm", this.type, row, this.index);
      this.visible = false;
    },
    // 获取常用人信息
    getList() {
      this.loading = true;

      if (this.$store.state.user.authority === 0) {
        // 用户
        getMembers({ userId: this.$store.state.user.id })
          .then(res => {
            this.$store.commit("SET_MEMBERS", res.data.list);
            this.allTableData = deepClone(res.data.list);
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      } else {
        // 管理员
        getMembersAdmin({ userId: this.$store.state.user.id })
          .then(res => {
            this.$store.commit("SET_MEMBERS", res.data.list);
            this.allTableData = deepClone(res.data.list);
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    handleShowUnSelectedPlayer() {
      if (this.showUnSelectedPlayer) {
        this.tableData = deepClone(this.allTableData);
      } else {
        this.tableData = this.allTableData.filter(item => {
          return !this.choose.find(el => el === item.cardId);
        });
      }
    }
  },
  components: {}
};
</script>

<style scoped>
.common-applicants__choose >>> .el-dialog {
  width: 550px;
  max-width: 90%;
}

.flex {
  display: flex;
}
.common-table_mb {
  display: none;
}
.common-table_mb .img_box {
  margin-right: 8px;
}
.common-table_mb img {
  width: 20px;
  height: auto;
}
.common-table_mb .card_box {
  margin-bottom: 25px;
}
.common-table_mb .card_box:last-child {
  margin-bottom: 5px;
}
.content_box p:first-child {
  font-weight: bold;
}
.grey {
  color: #999999;
}
.padding_top-xm {
  padding-top: 8px;
}
.group_box {
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}
.right_box {
  text-align: right;
}
.common-table_mb ul li {
  display: flex;
}
@media screen and (max-width: 991px) {
  .common-table_pc {
    display: none;
  }
  .common-table_mb {
    display: block;
  }
}
</style>
