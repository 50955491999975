/*
 * @Author: Saving
 * @Date: 2024-03-31 09:25:38
 * @LastEditors: Saving
 * @LastEditTime: 2024-03-31 09:42:14
 * @Description: 报名自由搏击
 */
import { BASE_URL } from "@/const";
import request from "@/utils/request";

// 报项-自由搏击-新增
export function add(data) {
  return request({
    url: `${BASE_URL}/api/kickboxing`,
    method: "post",
    data
  });
}

// 报项-自由搏击-删除
export function _delete(id) {
  return request({
    url: `${BASE_URL}/api/kickboxing/${id}`,
    method: "delete"
  });
}

// 报项-自由搏击-列表
export function getList(itemType) {
  return request({
    url: `${BASE_URL}/api/kickboxings`,
    method: "get",
    params: {
      itemType
    }
  });
}

// 报项-自由搏击-修改
export function modify(data) {
  return request({
    url: `${BASE_URL}/api/kickboxing/${data.id}`,
    method: "put",
    data,
  });
}



// 管理员
// 报项-自由搏击-获取列表
export function getPersonsAdmin() {
  return request({
    url: `${BASE_URL}/admin/persons`,
    method: "get"
  });
}
// 报项-自由搏击-添加
export function addPersonAdmin(data) {
  return request({
    url: `${BASE_URL}/admin/person`,
    method: "post",
    data
  });
}
// 报项-自由搏击-修改
export function putPersonAdmin(id, data) {
  return request({
    url: `${BASE_URL}/admin/person/${id}`,
    method: "put",
    data
  });
}
// 报项-自由搏击-删除
export function delPersonAdmin(id) {
  return request({
    url: `${BASE_URL}/admin/person/${id}`,
    method: "delete"
  });
}