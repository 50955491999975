/*
 * @Author: ChileeHong
 * @Date: 2021-08-19 16:30:10
 * @LastEditTime: 2024-08-24 17:38:40
 * @LastEditors: Saving
 * @Description: 深克隆
 */
import _ from "lodash";

// 使用lodash的cloneDeep方法， 才能保持自定义对象的类型
export function deepClone(obj) {
  return _.cloneDeep(obj);
}
