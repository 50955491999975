// itemType: 项目类型：0-拳术 1-器械
export const PROJECT_LIST = [
  {
    label: "竞技套路",
    value: "竞技套路",
    type: 0, // 对应 boxingType
    options: [
      {
        label: "自选项目",
        value: 0, // 对应 wushuType
        routinueType: 0,
        options: [
          {
            value: "自选长拳",
            label: "自选长拳",
            itemType: 0
          },
          {
            value: "自选刀术",
            label: "自选刀术",
            itemType: 1
          },
          {
            value: "自选棍术",
            label: "自选棍术",
            itemType: 1
          },
          {
            value: "自选剑术",
            label: "自选剑术",
            itemType: 1
          },
          {
            value: "自选枪术",
            label: "自选枪术",
            itemType: 1
          },
          {
            value: "自选南拳",
            label: "自选南拳",
            itemType: 0
          },
          {
            value: "自选南刀",
            label: "自选南刀",
            itemType: 1
          },
          {
            value: "自选南棍",
            label: "自选南棍",
            itemType: 1
          },
          {
            value: "自选太极拳",
            label: "自选太极拳",
            itemType: 0
          },
          {
            value: "自选太极剑",
            label: "自选太极剑",
            itemType: 1
          }
        ]
      },
      {
        label: "规定项目（国家规定套路）",
        value: 1,
        routinueType: 1,
        options: [
          {
            value: "规定长拳",
            label: "规定长拳",
            itemType: 0
          },
          {
            value: "规定刀术",
            label: "规定刀术",
            itemType: 1
          },
          {
            value: "规定棍术",
            label: "规定棍术",
            itemType: 1
          },
          {
            value: "规定剑术",
            label: "规定剑术",
            itemType: 1
          },
          {
            value: "规定枪术",
            label: "规定枪术",
            itemType: 1
          },
          {
            value: "规定南拳",
            label: "规定南拳",
            itemType: 0
          },
          {
            value: "规定南刀",
            label: "规定南刀",
            itemType: 1
          },
          {
            value: "规定南棍",
            label: "规定南棍",
            itemType: 1
          }
        ]
      },
      {
        label: "初级套路（国家规定套路）",
        value: 2,
        routinueType: 0,
        options: [
          {
            value: "五步拳",
            label: "五步拳",
            itemType: 0
          },
          {
            value: "一路长拳",
            label: "一路长拳",
            itemType: 0
          },
          {
            value: "二路长拳",
            label: "二路长拳",
            itemType: 0
          },
          {
            value: "三路长拳",
            label: "三路长拳",
            itemType: 0
          },
          {
            value: "初级刀",
            label: "初级刀",
            itemType: 1
          },
          {
            value: "初级剑",
            label: "初级剑",
            itemType: 1
          },
          {
            value: "初级棍",
            label: "初级棍",
            itemType: 1
          },
          {
            value: "初级枪",
            label: "初级枪",
            itemType: 1
          }
        ]
      },
      {
        label: "竞赛太极拳（国家规定套路）",
        value: 3,
        routinueType: 0,
        options: [
          {
            value: "24式太极拳",
            label: "24式太极拳",
            itemType: 0
          },
          {
            value: "42式太极拳",
            label: "42式太极拳",
            itemType: 0
          },
          {
            value: "42式太极剑",
            label: "42式太极剑",
            itemType: 1
          },
          {
            value: "32式太极剑",
            label: "32式太极剑",
            itemType: 1
          },
          {
            value: "陈式竞赛套路",
            label: "陈式竞赛套路",
            itemType: 0
          },
          {
            value: "杨式竞赛套路",
            label: "杨式竞赛套路",
            itemType: 0
          },
          {
            value: "吴式竞赛套路",
            label: "吴式竞赛套路",
            itemType: 0
          },
          {
            value: "武式竞赛套路",
            label: "武式竞赛套路",
            itemType: 0
          },
          {
            value: "孙式竞赛套路",
            label: "孙式竞赛套路",
            itemType: 0
          },
          {
            value: "八法五步",
            label: "八法五步",
            itemType: 0
          }
        ]
      }
    ]
  },
  {
    label: "传统套路",
    value: "传统套路",
    type: 1,
    options: [
      {
        value: "各类传统南派拳术",
        label: "各类传统南派拳术",
        itemType: 0,
        options: [
          {
            value: "洪拳",
            label: "洪拳",
            itemType: 0
          },
          {
            value: "咏春拳",
            label: "咏春拳",
            itemType: 0
          },
          {
            value: "蔡李佛拳",
            label: "蔡李佛拳",
            itemType: 0
          },
          {
            value: "白眉拳",
            label: "白眉拳",
            itemType: 0
          },
          {
            value: "龙形拳",
            label: "龙形拳",
            itemType: 0
          },
          {
            value: "南家拳",
            label: "南家拳",
            itemType: 0
          },
          {
            value: "侠家拳",
            label: "侠家拳",
            itemType: 0
          },
          {
            value: "其他南拳",
            label: "其他南拳",
            itemType: 0
          }
        ]
      },
      {
        value: "各类传统北派拳术",
        label: "各类传统北派拳术",
        itemType: 0,
        options: [
          {
            value: "鹰爪拳",
            label: "鹰爪拳",
            itemType: 0
          },
          {
            value: "螳螂拳",
            label: "螳螂拳",
            itemType: 0
          },
          {
            value: "少林拳",
            label: "少林拳",
            itemType: 0
          },
          {
            value: "其他北派拳",
            label: "其他北派拳",
            itemType: 0
          }
        ]
      },
      {
        value: "传统短器械",
        label: "传统短器械",
        itemType: 1
      },
      {
        value: "传统长器械",
        label: "传统长器械",
        itemType: 1
      },
      {
        value: "传统软器械",
        label: "传统软器械",
        itemType: 1
      },
      {
        value: "传统双器械",
        label: "传统双器械",
        itemType: 1
      },
      {
        value: "各式传统太极拳",
        label: "各式传统太极拳",
        itemType: 0,
        options: [
          {
            value: "传统陈式太极拳",
            label: "传统陈式太极拳",
            itemType: 0
          },
          {
            value: "传统杨式太极拳",
            label: "传统杨式太极拳",
            itemType: 0
          },
          {
            value: "传统孙式太极拳",
            label: "传统孙式太极拳",
            itemType: 0
          },
          {
            value: "传统武式太极拳",
            label: "传统武式太极拳",
            itemType: 0
          },
          {
            value: "传统吴式太极拳",
            label: "传统吴式太极拳",
            itemType: 0
          },
          {
            value: "华岳心意六合八法拳",
            label: "华岳心意六合八法拳",
            itemType: 0
          },
          {
            value: "其他传统太极拳",
            label: "其他传统太极拳",
            itemType: 0
          }
        ]
      },
      {
        value: "各式传统太极器械",
        label: "各式传统太极器械",
        itemType: 1
      }
    ]
  }
];
