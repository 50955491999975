<template>
  <div class="step3-person" v-loading="loading">
    <div class="empty" v-if="list.length < 1">暂无数据，请新增报项</div>
    <el-collapse
      v-else
      v-model="active"
      @change="handleCollapseChange"
      class="box"
    >
      <el-collapse-item
        :name="index"
        v-for="(item, index) in list"
        :key="index"
      >
        <template slot="title">
          {{
            index +
              1 +
              ". " +
              item.name +
              "（" +
              item.tuishouGroup +
              "：" +
              item.tuishouType +
              item.weight +
              "）"
          }}
          <span v-if="item.projectName">&nbsp;|</span>
          <span style="color:#409EFF;margin:0 4px">
            {{ item.projectName }}
          </span>

          <div class="title-right">
            <!-- <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click.stop="edit(index, item)"
              >编辑</el-button
            > -->
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click.stop="del(item.id)"
              >删除</el-button
            >
          </div>
        </template>
        <div class="personal-info-item">
          <el-form class="form-wrap" label-width="140px">
            <el-form-item label="姓名：">
              <div class="form-user">
                <label>{{ item | nameFormat }}</label>
              </div>
            </el-form-item>
            <el-form-item label="项目名称：" v-if="item.projectName">
              <el-input
                style="width: 100%;"
                v-model="item.projectName"
                readonly
              />
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div style="text-align:center; padding: 30px 0 0;">
      <el-button type="primary" @click="handleAdd()">新增对抗报项 </el-button>
    </div>

    <KickboxingEdit
      ref="editRef"
      :type-options="typeOptions"
      :boxingTradition="boxingTradition"
      :instrument-tradition="instrumentTradition"
      @confirm="confirm"
      @change-type="handleChangeType"
      @choose-user="handleChoose"
    />
  </div>
</template>

<script>
import {
  _delete,
  add,
  modify,
  getList,
  delPersonAdmin,
  addPersonAdmin,
  putPersonAdmin,
  getPersonsAdmin
} from "@/api/Registration/RegisterKickboxingApi.js";
import KickboxingEdit from "./KickboxingEdit.vue";
import { PROJECT_LIST } from "@/constData/projectRelavant.js";

export default {
  name: "step3Person",
  components: {
    KickboxingEdit
  },
  props: {},
  data() {
    return {
      loading: false,
      active: [],
      typeOptions: ["竞技套路", "传统套路武术赛"],
      boxingTradition: [],
      instrumentTradition: [],
      list: [],
      isLimitEnlistsTime: true // 是否限制报名次数
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    getList() {
      this.loading = true;

      if (this.$store.state.user.authority === 0) {
        // 用户
        getList()
          .then(res => {
            console.log("res", res);
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      } else {
        // 管理员
        getPersonsAdmin()
          .then(res => {
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    handleChangeType(val) {
      switch (val) {
        case "竞技套路":
          this.boxingTradition = PROJECT_LIST[0].options;
          break;
        case "传统套路武术赛":
          this.boxingTradition = PROJECT_LIST[1].options;
          break;
        default:
          break;
      }
    },
    handleChoose() {
      this.$emit("choose-user", "freeBoxingRef", 0, this.list);
    },
    // 添加项
    handleAdd() {
      this.$refs.editRef.show("add", -1);
    },
    handleCollapseChange(activeNames) {},

    applicantsConfirm(row) {
      console.log("row :>> ", row);
      this.$refs["editRef"].applicantsConfirm(row);
    },
    // 删除
    del(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (this.$store.state.user.authority === 0) {
            // 用户
            _delete(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                this.$message.error(err);
                console.error(err);
              });
          } else {
            // 管理员
            delPersonAdmin(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                this.$message.error(err);
                console.error(err);
              });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    edit(index, item) {
      // console.log('item', item)
      this.$refs.editRef.show("edit", index, item);
    },
    // 保存
    confirm(index, row) {
      if (row.boxing === row.instrument && row.boxing && row.instrument) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      if (row.boxing === row.boxing1 && row.boxing && row.boxing1) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      if (row.boxing1 === row.instrument && row.boxing1 && row.instrument) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      let check = this.list.filter((item, listIndex) => {
        // 如果是新增运动员
        if (index === -1) {
          return (
            item.cardId === row.cardId && item.tuishouType === row.tuishouType
          );
          // 如果是修改运动员
        } else if (index !== listIndex) {
          return (
            item.cardId === row.cardId && item.tuishouType === row.tuishouType
          );
        }
      });

      if (this.isLimitEnlistsTime) {
        if (check.length >= 1) {
          this.$alert("填报失败，不允许填报相同类型的个人项目。", "提示", {
            confirmButtonText: "确定",
            type: "error"
          });
          return false;
        }
      }

      if (index === -1) {
        if (this.$store.state.user.authority === 0) {
          // 用户
          add(row)
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.editRef.hide();
            })
            .catch(err => {
              this.$message.error(err);
              console.error(err);
            });
        } else {
          // 管理员
          addPersonAdmin(row)
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.editRef.hide();
            })
            .catch(err => {
              this.$message.error(err);
              console.error(err);
            });
        }
      } else if (this.$store.state.user.authority === 0) {
        // 用户
        modify(row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.editRef.hide();
          })
          .catch(err => {
            this.$message.error(err);
            console.error(err);
          });
      } else {
        // 管理员
        putPersonAdmin(row.id, row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.editRef.hide();
          })
          .catch(err => {
            this.$message.error(err);
            console.error(err);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.empty {
  height: 100px;
  line-height: 120px;
  text-align: center;
}
.box {
  min-height: 200px;
  max-height: 400px;
  line-height: 400px;
  overflow: auto;
}
.title-right {
  position: absolute;
  right: 35px;
}
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-footer {
    text-align: center;
  }
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
</style>
