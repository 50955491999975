var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"common-applicants__choose",attrs:{"title":_vm.title[_vm.type] + '个人报项',"before-close":_vm.handleClose,"visible":_vm.visible,"width":"580px","close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"formEdit",staticClass:"form-wrap",attrs:{"hide-required-asterisk":true,"model":_vm.formData,"rules":_vm.rules,"label-width":"140px"}},[_c('el-form-item',{attrs:{"prop":"name"}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 运动员： ")]),_c('el-input',{staticStyle:{"width":"100%","max-width":"400px"},attrs:{"placeholder":"点击选择运动员","readonly":"","disabled":_vm.type === 'edit'},nativeOn:{"click":function($event){return _vm.handleChoose($event)}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-user"})])],2)],2),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.user),expression:"user"}],attrs:{"prop":"boxingType"}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 套路类型： ")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":_vm.handleTypeChange},model:{value:(_vm.formData.boxingType),callback:function ($$v) {_vm.$set(_vm.formData, "boxingType", $$v)},expression:"formData.boxingType"}},_vm._l((_vm.typeOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":index}})}),1)],2),(
        typeof _vm.formData.boxingType === 'number' && _vm.formData.boxingType === 0
      )?_c('el-form-item',{attrs:{"prop":"wushuType"}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 竞技类型： ")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":_vm.handleChangeWushuType},model:{value:(_vm.formData.wushuType),callback:function ($$v) {_vm.$set(_vm.formData, "wushuType", $$v)},expression:"formData.wushuType"}},_vm._l((_vm.routinueTypeOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],2):_vm._e(),(
        typeof _vm.formData.wushuType === 'number' || _vm.formData.boxingType === 1
      )?_c('el-form-item',{attrs:{"prop":"boxing"}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 报项名称： ")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.boxing),callback:function ($$v) {_vm.$set(_vm.formData, "boxing", $$v)},expression:"formData.boxing"}},_vm._l((_vm.boxingTradition),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],2):_vm._e(),(
        _vm.formData.boxing === '各类传统南派拳术' ||
          _vm.formData.boxing === '各类传统北派拳术' ||
          _vm.formData.boxing === '各式传统太极拳'
      )?_c('el-form-item',{attrs:{"prop":"quanshuName"}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 拳术名称： ")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.quanshuName),callback:function ($$v) {_vm.$set(_vm.formData, "quanshuName", $$v)},expression:"formData.quanshuName"}},_vm._l((_vm.quanshuList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],2):_vm._e(),(_vm.formData.boxingType === 1 && _vm.formData.boxing)?_c('el-form-item',{attrs:{"prop":"boxingRoutine","rules":_vm.rules.boxingRoutine}},[_c('template',{slot:"label"},[_c('span',{staticStyle:{"color":"#F56C6C","margin-right":"4px"}},[_vm._v("*")]),_vm._v(" 套路名称： ")]),_c('el-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请补充套路名称"},model:{value:(_vm.formData.boxingRoutine),callback:function ($$v) {_vm.$set(_vm.formData, "boxingRoutine", $$v)},expression:"formData.boxingRoutine"}})],2):_vm._e()],1),_c('div',{staticClass:"form-footer"},[(_vm.type === 'add')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleAdd('formEdit')}}},[_vm._v("新增")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleUpdate('formEdit')}}},[_vm._v("修改")]),_c('el-button',{on:{"click":function($event){return _vm.handleCancel('formEdit')}}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }