<template>
  <div class="step3-wrap container-1200">
    <el-tabs v-model="active" @tab-click="handleTabClick">
      <el-tab-pane label="个人项目" name="person">
        <person
          class="match-item"
          ref="person"
          @choose-user="handleChoose"
        ></person>
      </el-tab-pane>
      <el-tab-pane label="对练项目" name="duel">
        <duel
          class="match-item"
          ref="duel"
          @choose-user="handleChoose"></duel>
      </el-tab-pane>
      <el-tab-pane label="集体项目" name="collective">
        <collective
          class="match-item"
          ref="collective"
          @choose-user="handleChoose"
        ></collective>
      </el-tab-pane>

      <el-tab-pane label="对抗项目" name="freeBoxing">
        <Kickboxing
          class="match-item"
          ref="freeBoxingRef"
          @choose-user="handleChoose"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="step-btn-group">
      <el-button type="primary" @click="prevStep()">上一步</el-button>
      <el-button type="primary" @click="nextStep()">下一步</el-button>
    </div>
    <CommonApplicants
      ref="commonApplicantsRef"
      @confirm="applicantsConfirm"
    />
  </div>
</template>

<script>
// import { getItems } from '@/api'
import CommonApplicants from "../components/CommonApplicants.vue";
import Person from "./components/person.vue";
import Duel from './components/duel'
import Collective from "./components/collective.vue";
import Kickboxing from "./components/Kickboxing.vue";

export default {
  name: "step3",
  components: {
    CommonApplicants,
    Person,
    Duel,
    Collective,
    Kickboxing,
  },
  props: {},
  data() {
    return {
      active: "person",
      loading: true
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleChoose(type, index, list, projectType) {
      this.$refs.commonApplicantsRef.show(type, index, list, projectType);
    },
    applicantsConfirm(type, row, index) {
      this.$refs[type].applicantsConfirm(row, index);
    },
    handleTabClick() {},

    prevStep() {
      this.$store.dispatch("STEP_PREV", {
        router: this.$router,
        route: this.$route
      });
    },
    nextStep() {
      this.$store.dispatch("STEP_NEXT", {
        router: this.$router,
        route: this.$route
      });
    }
  }
};
</script>

<style scoped lang="scss">
.step3-wrap {
  max-width: 1200px;
  margin: auto;
}
</style>
