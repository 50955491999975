<template>
  <div class="step3-person" v-loading="loading">
    <div class="empty" v-if="list.length < 1">暂无数据，请新增报项</div>
    <el-collapse
      v-else
      v-model="active"
      @change="handleCollapseChange"
      class="box"
    >
      <el-collapse-item
        :name="index"
        v-for="(item, index) in list"
        :key="index"
      >
        <template slot="title">
          {{ index + 1 + ". " + item.name + "（" + item.group + "）" }}
          <span v-if="item.boxing">&nbsp;|</span>
          <span style="color:#409EFF;margin:0 4px">
            {{
              item.boxing
                ? item.boxing +
                  (item.boxingRoutine ? "-" + item.boxingRoutine : "")
                : ""
            }}
          </span>

          <span v-if="item.instrument">&nbsp;|</span>
          <span style="color:#409EFF;margin:0 4px">
            {{
              item.instrument
                ? item.instrument +
                  (item.instrumentRoutine ? "-" + item.instrumentRoutine : "")
                : ""
            }}
          </span>

          <!-- <span v-if="item.instrument1">&nbsp;|</span>
          <span style="color:#409EFF;margin:0 4px">
            {{ item.instrument1 ? item.instrument1 + (item.instrumentRoutine1 ? '-' + item.instrumentRoutine1 : '') : '' }}
          </span> -->
          <div class="title-right">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click.stop="edit(index, item)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click.stop="del(item.id)"
              >删除</el-button
            >
            <!-- <i
              class="header-icon el-icon-edit"
              @click.stop="edit(index)"
              style="margin-right: 10px;"
            ></i>
            <i class="header-icon el-icon-delete" @click.stop="del(index)"></i>-->
          </div>
        </template>
        <div class="personal-info-item">
          <el-form class="form-wrap" label-width="140px">
            <el-form-item label="姓名：">
              <div class="form-user">
                <label>{{ item | nameFormat }}</label>
              </div>
            </el-form-item>
            <el-form-item label="项目名称：" v-if="item.boxing">
              <el-input style="width: 100%;" v-model="item.boxing" readonly />
            </el-form-item>
            <el-form-item label="项目套路名称：" v-if="item.boxingRoutine">
              <el-input
                style="width: 100%;"
                v-model="item.boxingRoutine"
                readonly
              />
            </el-form-item>
            <el-form-item label="器械项目名称：" v-if="item.instrument">
              <el-input
                style="width: 100%;"
                v-model="item.instrument"
                readonly
              />
            </el-form-item>
            <el-form-item label="器械名称：" v-if="item.instrumentRoutine">
              <el-input
                style="width: 100%;"
                v-model="item.instrumentRoutine"
                readonly
              />
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div style="text-align:center; padding: 30px 0 0;">
      <el-button type="primary" @click="handleAdd()"
        >新增武术个人报项</el-button
      >
    </div>

    <PersonEdit
      ref="editRef"
      :instrument-tradition="instrumentTradition"
      @confirm="confirm"
      @choose-user="handleChoose"
    ></PersonEdit>
  </div>
</template>

<script>
import {
  delPerson,
  addPerson,
  putPerson,
  getPersons,
  delPersonAdmin,
  addPersonAdmin,
  putPersonAdmin,
  getPersonsAdmin
} from "@/api";
import PersonEdit from "./PersonEdit.vue";

export default {
  name: "step3Person",
  components: {
    PersonEdit
  },
  props: {},
  data() {
    return {
      loading: false,
      active: [],
      // 器械项目选项
      instrumentTradition: [],
      list: [],
      isLimitEnlistsTime: true // 是否限制报相同类型项目
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    getList() {
      this.loading = true;

      if (this.$store.state.user.authority === 0) {
        // 用户
        getPersons()
          .then(res => {
            console.log("res", res);
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      } else {
        // 管理员
        getPersonsAdmin()
          .then(res => {
            this.loading = false;
            this.$store.commit("SET_STATUS", res.data.status);
            this.list = res.data.list;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    handleChoose() {
      this.$emit("choose-user", "person", 0, this.list);
    },
    // 添加项
    handleAdd() {
      this.$refs.editRef.show("add", -1);
    },
    handleCollapseChange(activeNames) {},

    applicantsConfirm(row) {
      this.$refs["editRef"].applicantsConfirm(row);
    },
    // 删除
    del(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (this.$store.state.user.authority === 0) {
            // 用户
            delPerson(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                this.$message.error(err);
                console.error(err);
              });
          } else {
            // 管理员
            delPersonAdmin(id)
              .then(res => {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
              })
              .catch(err => {
                this.$message.error(err);
                console.error(err);
              });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    edit(index, item) {
      console.log("item", item);
      this.$refs.editRef.show("edit", index, item);
    },
    // 保存
    confirm(index, row) {
      if (row.boxing === row.instrument && row.boxing && row.instrument) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      if (row.boxing === row.boxing1 && row.boxing && row.boxing1) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      if (row.boxing1 === row.instrument && row.boxing1 && row.instrument) {
        this.$message.error("不可报相同项目");
        return 0;
      }
      let check = this.list.filter((item, listIndex) => {
        // 如果是新增运动员
        if (index === -1) {
          return item.cardId === row.cardId && item.boxing === row.boxing;
        } else if (index !== listIndex) {
          // 如果是修改运动员
          return item.cardId === row.cardId && item.boxing === row.boxing;
        }
      });

      console.log("row :>> ", row);
      let sameUserProjects = this.list.filter(el => el.cardId === row.cardId);
      if (sameUserProjects.length) {
        // 如果是新增运动员
        if (index === -1) {
          // 个人项目最多可报三项：一项拳术、两项器械
          if (sameUserProjects.length >= 3) {
            this.$alert("填报失败，个人项目最多可报三项。", "提示", {
              confirmButtonText: "确定",
              type: "error"
            });
            return;
          }
        } else {
          // 编辑运动员
        }
        // itemType 项目类型 0-武术拳术 1-武术器械
        // 同项目类型的报项数量
        let count = sameUserProjects.filter(
          item => item.itemType === row.itemType
        );
        if (
          row.itemType === 0 &&
          ((index === -1 && count >= 1) ||
            (index !== -1 && this.list[index].itemType == row.itemType))
        ) {
          this.$alert("填报失败，个人项目最多可报一项拳术。", "提示", {
            confirmButtonText: "确定",
            type: "error"
          });
          return;
        }
        if (
          row.itemType === 1 &&
          sameUserProjects.filter(item => item.itemType === row.itemType)
            ?.length >= 2
        ) {
          this.$alert("填报失败，个人项目最多可报两项器械。", "提示", {
            confirmButtonText: "确定",
            type: "error"
          });
          return;
        }
      }

      if (check.length >= 1) {
        if (this.isLimitEnlistsTime) {
          this.$alert("填报失败，不允许填报相同类型的个人项目。", "提示", {
            confirmButtonText: "确定",
            type: "error"
          });
          return false;
        }
      }

      if (index === -1) {
        if (this.$store.state.user.authority === 0) {
          // 用户
          addPerson({ ...row })
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.editRef.hide();
            })
            .catch(err => {
              this.$message.error(err);
              console.error(err);
            });
        } else {
          // 管理员
          addPersonAdmin({ ...row })
            .then(res => {
              this.$message.success(res.data.message);
              this.getList();
              this.$refs.editRef.hide();
            })
            .catch(err => {
              this.$message.error(err);
              console.error(err);
            });
        }
      } else if (this.$store.state.user.authority === 0) {
        // 用户
        putPerson(row.id, row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.editRef.hide();
          })
          .catch(err => {
            this.$message.error(err);
            console.error(err);
          });
      } else {
        // 管理员
        putPersonAdmin(row.id, row)
          .then(res => {
            this.$message.success(res.data.message);
            this.getList();
            this.$refs.editRef.hide();
          })
          .catch(err => {
            this.$message.error(err);
            console.error(err);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.empty {
  height: 100px;
  line-height: 120px;
  text-align: center;
}
.box {
  min-height: 200px;
  max-height: 400px;
  line-height: 400px;
  overflow: auto;
}
.title-right {
  position: absolute;
  right: 35px;
}
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-footer {
    text-align: center;
  }
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
</style>
